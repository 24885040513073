.container {
  position: relative;
  width: 100%;
  max-width: 300px;
  border-radius: 10px;
}

.container img {
  width: 260px;
  height: 280px;
  border-radius: 10px;
}

.container .btn {
  position: absolute;
  top: 10%;
  left: 90%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #555;
  color: rgba(253, 253, 253, 0.952);
  font-size: 10px;
  padding: 6px 7px;
  border: none;
  cursor: pointer;
  border-radius: 15px;
  text-align: center;
}

.container .btn:hover {
  background-color: red;
}
